import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="anchor"
export default class extends Controller {
  static targets = ["content"]

  connect() {
    this.checkHash()
    this.boundCheckHash = () => setTimeout(this.checkHash.bind(this), 0)
    window.addEventListener("hashchange", this.boundCheckHash)
    document.addEventListener("turbo:load", this.checkHash.bind(this))
  }

  checkHash() {
    const hash = window.location.hash
    if (hash == this.#hash) {
      this.contentTarget.style.display = "block"
      this.contentTarget.style.opacity = 1
    } else {
      this.contentTarget.style.display = "none"
      this.contentTarget.style.opacity = 0
    }
  }

  disconnect() {
    window.removeEventListener("hashchange", this.boundCheckHash)
    document.addEventListener("turbo:load", this.checkHash.bind(this))
  }

  get #hash() {
    return `#${this.element.id}`
  }
}
