import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropdown"]

  connect() {
    this.dropdownTarget.style.display = "none"
  }

  mouseover() {
    this.dropdownTarget.style.display = "block"
    this.dropdownTarget.classList.add("w--open")
  }

  mouseout() {
    this.dropdownTarget.style.display = "none"
    this.dropdownTarget.classList.remove("w--open")
  }

  // connect() {
  //   this.element.classList.add(`fade-in-from-${this.directionValue}`)
  //   this.observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         this.element.style.animationPlayState = "running"
  //       }
  //     })
  //   })

  //   this.observer.observe(this.element)
  // }

  // disconnect() {
  //   this.observer.unobserve(this.element)
  // }
}
