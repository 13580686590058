import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="menu"
export default class extends Controller {
  static targets = ["button", "item", "menu", "openIcon", "closedIcon"]

  static values = {
    open: { type: Boolean, default: false },
  }

  toggle() {
    this.openValue = !this.openValue
  }

  hide(event) {
    if (this.element.contains(event.target) === false && this.openValue) {
      this.openValue = false
    }
  }

  openValueChanged() {
    if (this.openValue) {
      this._open()
    } else {
      this._close()
    }
    this.buttonTarget?.setAttribute("aria-expanded", this.openValue)
  }

  close() {
    this.openValue = false
  }

  // private

  _close() {
    if (this.hasOpenIconTarget) this.openIconTarget.classList.add("hidden")
    if (this.hasClosedIconTarget) this.closedIconTarget.classList.remove("hidden")
    if (this.hasButtonTarget) this.buttonTarget.classList.remove("w--open")
    if (this.hasMenuTarget) {
      this.menuTarget.classList.add("w--open")
      this.menuTarget.removeAttribute("data-nav-menu-open")
    }
    if (this.itemTargets.length > 0) {
      this.itemTargets.forEach((item) => {
        item.classList.remove("w--nav-link-open")
      })
    }

    this.element.classList.remove("w--open")

    // this.menuTarget.classList.add("hidden")
  }

  _open() {
    if (this.hasOpenIconTarget) this.openIconTarget.classList.remove("hidden")
    if (this.hasClosedIconTarget) this.closedIconTarget.classList.add("hidden")
    if (this.hasButtonTarget) this.buttonTarget.classList.add("w--open")
    if (this.hasMenuTarget) {
      this.menuTarget.classList.add("w--open")
      this.menuTarget.setAttribute("data-nav-menu-open", true)
    }
    if (this.itemTargets.length > 0) {
      this.itemTargets.forEach((item) => {
        item.classList.add("w--nav-link-open")
      })
    }

    this.element.classList.add("w--open")

    // this.menuTarget.classList.remove("hidden")
  }
}
