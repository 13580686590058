import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { direction: String }

  connect() {
    this.element.classList.add(`fade-in-from-${this.directionValue}`)
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.element.style.animationPlayState = "running"
        }
      })
    })

    this.observer.observe(this.element)
  }

  disconnect() {
    this.observer.unobserve(this.element)
  }
}
